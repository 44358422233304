.notFoundContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.notFoundContainer h2 {
  margin-bottom: 50px;
}