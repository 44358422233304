.appContainer {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  background-color: #f5f8ff;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  flex-direction: column;
}

body {
  overflow-x: hidden;
}

spam{
  font-size: 16px !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.secondaryCard {
  padding:10px;
  margin-bottom:15px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
}

.mainCard {
  width: 90%;
  max-width: 1000px;
  padding: 20px;
  margin:10px;
  box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.1);
}

.mainForm {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inputError {
  background-color: yellow;
  font-weight: bold;
}

.donateBox {
  height: 80px;
  width: 100px;
  background-color: #f7f7f7;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  border: 2px solid red;
  padding: 5px;
}

.donateBoxSelected {
  height: 80px;
  width: 100px;
  background-color: red;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  padding: 5px;
  box-shadow: 0px 0px 5px 1px rgba(255, 0, 0, 0.4);
}

.donateText {
  align-self: baseline;
  color: red;
  font-size: 20px;
  font-weight: bold;
}

.donateTextSelected {
  align-self: baseline;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.bigText {
  font-size: 40px;
}

.rowAgroup {
  justify-content: space-evenly;
}

.btnConfirm {
  color: #fff !important;
  background-color: gray !important;
  border-color: gray !important;
}

h3 {
  color: #cc0000;
}

.themeText {
  color: #cc0000;
  font-weight: bold;
  font-size: 18px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bordered {
  box-shadow: 0px 0px 1px 1px rgba(255, 0, 0, 1) !important;
}

.logo{
  width: 100%;
  object-fit: contain;
}

.open-checkout{
  margin: 10px 0px;
}

.flagCardContainer{
  display:flex;
  flex-direction:row;
  width:100%;
  justify-content:space-between;
  flex-wrap:wrap;
}

.col{
  display:flex;
  flex-direction:column;
}

.flagCard{
  height:40px;
  max-width:350px;
  object-fit: scale-down;
  align-self: flex-start;
}